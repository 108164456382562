import ProductRoute from "./ProductRoute";
import { lazy } from 'react';

// project imports
import Loadable from '../ui-component/Loadable';
// import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Register3')));
const ForgetLayout = Loadable(lazy(() => import('../views/pages/authentication/authentication3/ForgetPassLayout')));
const SiteSettings = Loadable(lazy(() => import("../views/pages/SiteSettings")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  children: [
    {
      path: '/',
      element: <AuthLogin3 />
    },
    {
      path: '/register',
      element: <AuthRegister3 />
    },
    {
      path: '/forget-pass',
      element: <ForgetLayout />
    },
    {
      path: "siteSettings",
      element:<ProductRoute><SiteSettings /></ProductRoute>,
    },
  ]
};

export default AuthenticationRoutes;