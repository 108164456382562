import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store } from './store';

// style + assets
import './assets/scss/style.scss';
import config from './config';
import "react-toastify/dist/ReactToastify.css";


const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
