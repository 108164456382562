import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
import Loadable from "../ui-component/Loadable";

// Product Route
import ProductRoute from "./ProductRoute";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
const Transactions = Loadable(
  lazy(() => import("../views/dashboard/Default/Transaction"))
);
const SwapHistory = Loadable(
  lazy(() => import("../views/dashboard/Default/SwapHistory"))
);
const StackHistory = Loadable(
  lazy(() => import("../views/dashboard/Default/StackHistory"))
);
const UserDetails = Loadable(lazy(()=>import("../views/dashboard/Default/UserWalletData")));
const Withdraw = Loadable(lazy(()=>import("../views/pages/authentication/authentication3/WithdrawForm3")))
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",

  element: (
    <ProductRoute>
      <MainLayout />
    </ProductRoute>
  ),
  children: [
    {
      path: "dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard/Transaction",
      element: <Transactions />,
    },
    {
      path: "dashboard/SwapHistory",
      element: <SwapHistory />,
    },
    {
      path: "dashboard/StackHistory",
      element: <StackHistory />,
    },
    {
      path: "dashboard/UserDetails",
      element: <UserDetails />,
    },
    {
      path: "dashboard/Withdraw",
      element: <Withdraw />,
    },
  ],
};

export default MainRoutes;
