import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApiRequest } from "../../../../ApiRequest";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  OutlinedInput,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "../../../../ui-component/cards/MainCard";
import Transitions from "../../../../ui-component/extended/Transitions";
import User1 from "../../../../assets/images/users/user-round.svg";

// assets
import { IconLogout, IconSettings, IconUser } from "@tabler/icons-react";
import { CiBadgeDollar } from "react-icons/ci";
import { IoSettings } from "react-icons/io5";

import { ToastContainer, toast } from "react-toastify";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [adminProfile, setAdminProfile] = useState({});
  const [showPriceInput, setShowPriceInput] = useState(false);
  const [price, setPrice] = useState(null);
  const [livePrice, setLivePrice] = useState(0);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setShowPriceInput(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const getAdminProfile = async () => {
      try {
        const params = {
          url: "get-admin-profile",
          method: "get",
        };
        const res = await ApiRequest(params);
        if (res.status) {
          setAdminProfile(res.data);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    getAdminProfile();
  }, []);
  const handlePriceSubmit = async (e) => {
    e.preventDefault();
    try {
      const includeLetters = /^[0-9.]*$/;
      const isTrue = includeLetters.test(price);
      if (price) {
        if (isTrue) {
          if (price > 0) {
            const payload = {
              price: price,
            };
            const params = {
              url: "siteSettings",
              method: "post",
              data: payload,
            };
            const res = await ApiRequest(params);
            if (res.status) {
              toast.success(res.message);
              setShowPriceInput(false);
              setPrice(null);
              setTimeout(() => handleClose(e), 2000);
            } else {
              toast.error(res.message);
            }
          } else {
            toast.error("Please Enter Valuable Value!");
          }
        } else {
          toast.error("Numbers Only Allowed This Field!");
        }
      } else {
        toast.error("Please Enter Live Price!");
      }
    } catch (error) {
      toast.error("Something Went Wroung!");
    }
  };

  useEffect(() => {
    const getLivePrice = async () => {
      try {
        const params = {
          url: "getLivePrice",
          method: "get",
        };
        const res = await ApiRequest(params);
        if (res.status) {
          setLivePrice(res.data?.livePrice);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log("error:", error);
      }
    };
    getLivePrice();
  }, [showPriceInput]);
  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Morning,</Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400 }}
                        >
                          {adminProfile.firstName} {adminProfile.lastName}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2">Project Admin</Typography>
                    </Stack>
                    <Divider />
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Divider />

                      <Divider />
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                          <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                        >
                          <ListItemIcon>
                            <IoSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                onClick={() => {
                                  navigate("/siteSettings");
                                }}
                              >
                                Site Settings
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 1}
                        >
                          <ListItemIcon>
                            <CiBadgeDollar stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid
                                container
                                spacing={1}
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography variant="body2">
                                    Live Price
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Chip
                                    label={livePrice}
                                    size="small"
                                    sx={{
                                      bgcolor: theme.palette.warning.dark,
                                      color: theme.palette.background.default,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={() => {
                            localStorage.clear();
                            navigate("/");
                          }}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                              >
                                Logout
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
