// assets
import { IconDashboard } from '@tabler/icons-react';
import { FaHandHoldingDollar } from "react-icons/fa6";
import { AiOutlineTransaction } from "react-icons/ai";
import { IoMdSwap } from "react-icons/io";
import { BsStack } from "react-icons/bs";
import { FaUser } from "react-icons/fa6";
import { BiMoneyWithdraw } from "react-icons/bi";


// constant
const icons = { IconDashboard,FaHandHoldingDollar,AiOutlineTransaction,IoMdSwap,BsStack,FaUser,BiMoneyWithdraw};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'default',
      title: 'Swap History',
      type: 'item',
      url: '/dashboard/SwapHistory',
      icon: icons.IoMdSwap,
      breadcrumbs: false
    },
    {
      id: 'default',
      title: 'Stack History',
      type: 'item',
      url: '/dashboard/StackHistory',
      icon: icons.BsStack,
      breadcrumbs: false
    }, {
      id: 'default',
      title: 'User Details',
      type: 'item',
      url: '/dashboard/UserDetails',
      icon: icons.FaUser,
      breadcrumbs: false
    },
    {
      id: 'default',
      title: 'Withdraw',
      type: 'item',
      url: '/dashboard/Withdraw',
      icon: icons.BiMoneyWithdraw,
      breadcrumbs: false
    }
  ]
};
export default dashboard;
