const config = {
  basename: '/',
  defaultPath: '/dashboard/default',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  Admin_Url : "https://api.malgovault.com/trustWalletApi/v1/adminApi/",
  // Admin_Url:"http://localhost:4000/trustWalletApi/v1/adminApi/",
  keySecret:"TrustWallet@PassKey123@"
};

export default config;