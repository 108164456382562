import axios from "axios";
import Config from "./config";

export const ApiRequest = async (params) => {
  try {
    let getToken = localStorage.getItem('token');
    const token = getToken != null ? getToken : "";
    // console.log("authtoken",token);
    const headers = {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        "Access-Control-Allow-Headers": "*",
        "Authorization": `Bearer ${token}`,
    };
    let response;
    switch (params.method.toUpperCase()) {
      case "GET":
        response = await axios.get(`${Config.Admin_Url}${params.url}`, { headers });
        break;
      case "POST":
        response = await axios.post(`${Config.Admin_Url}${params.url}`, params.data, { headers });
        break;
      case "PUT":
        response = await axios.put(`${Config.Admin_Url}${params.url}`, params.data, { headers });
        break;
      case "DELETE":
        response = await axios.delete(`${Config.Admin_Url}${params.url}`, { headers });
        break;
      default:
        throw new Error(`Unsupported method: ${params.method}`);
    }
    return response.data;
  } catch (error) {
    console.log("axios error", error);
    throw error;
  }
};