import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AuthRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkAdminAuth = () => {
    const adminJwtToken = localStorage.getItem("token");
    if (
      !adminJwtToken ||
      adminJwtToken === "undefined" ||
      typeof adminJwtToken === undefined ||
      adminJwtToken == ""
    ) {
      localStorage.clear();
      setIsLoggedIn(false);
      navigate("/");
    } else {
      const adminJwt = jwtDecode(adminJwtToken);
      const currentDate = new Date();
      const expireDate = new Date(adminJwt.exp * 1000);
      if (currentDate < expireDate) {
        setIsLoggedIn(true);
      } else {
        localStorage.clear();
        setIsLoggedIn(false);
        navigate("/");
      }
    }
  };
  useEffect(() => {
    const interVal = setInterval(() => {
      checkAdminAuth();
    }, 5000);
    return () => clearInterval(interVal);
  }, []);

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};
export default AuthRoute;
